@import "../../styles/base/base.scss";

.ril__downloadButton {
  background: $button-download-icon no-repeat center;
}
.Toastify__toast-container--bottom-center {
  min-width: 400px;
}

.container-recording-advice {
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(100, 100, 100, 0.95);
  position: absolute; 
  bottom: 72px;
  right: 6%;
  z-index: 5;

  span {
    color: #fff;
    font-size: 12px;
  }
}

.container-recording-advice::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(100, 100, 100, 0.95);
}

@media (min-width: 600px) {
  .container-recording-advice {
    right: 3%;
  }
}

@media (min-width: 1920px) {
  .container-recording-advice {
    right: 4%;
  }
}